<script>
import helpers from "../../../helpers";

import {
  analyticsMethods,
} from "@/state/helpers";

export default {
  data() {
    return {
      
      chartOptions: {
            chart: {
              width: "100%",
              height: 200,
              type: "area",
              sparkline: {
                enabled: true,
              },
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
              width: 1.5,
            },
            fill: {
              type: "gradient",
              gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [50, 100, 100, 100],
              },
            },
            colors: ["#5e40bf"],
            xaxis: {
              type: 'datetime',
              show: false,
            },            
      },
      series: [
        {
          name: "Revenue",
          data: [],
        },
      ],
      broadcastsAnalytics:[],
      revenue: '-',
      executions: '-',
      delivery_rate: '-',
      open_rate: '-',
      click_rate: '-',
      conversion_rate: '-',
      periodLength: 0
    };
  },
  components:{},
  props: {
    dateFilter : Array
  },
  mounted(){
    const self = this;
    
    this.chartOptions.yaxis = {
        show: false,
        labels: {
          formatter: function (valor) {
            return `$${self.parseMoneyWithLocale(valor)}`;
          }
        }
      }
      this.$refs.revenueChart.updateOptions(this.chartOptions);
  },
  computed: {},
  methods: {
    ...analyticsMethods,
    ...helpers,

    applyFilter(query){
      this.getBroadcastsReports(query).then((res)=>{
        this.broadcastsAnalytics = res.data;
        
        this.series[0].data = Array.from(this.broadcastsAnalytics.map(x => [new Date(x.date).getTime(), parseFloat(x.revenue, 10)]));
        this.series[0].data.sort((a, b) => a[0] - b[0]);
        this.$refs.revenueChart?.updateSeries(this.series,true);

        this.executions = this.getSummaryByKey('executions',this.broadcastsAnalytics);
        const success = this.getSummaryByKey('success',this.broadcastsAnalytics); 
        const opened = this.getSummaryByKey('opened',this.broadcastsAnalytics);
        const views = this.getSummaryByKey('views',this.broadcastsAnalytics);
        const conversions = this.getSummaryByKey('converted',this.broadcastsAnalytics);
        this.conversion_rate = success == '-' ? `-` : success == 0 ? `0%` : `${this.parsePercentage(conversions/success * 100)}%`
        this.open_rate = success == '-' ? `-` : success == 0 ? `0%` : `${this.parsePercentage(opened/success * 100)}%`
        this.click_rate = success == '-' ? `-` : success == 0 ? `0%` : `${this.parsePercentage(views/success * 100)}%`
        this.delivery_rate = this.executions == '-' ? `-` : this.executions == 0 ? `0%` : `${this.parsePercentage(success/this.executions * 100)}%`
        this.revenue = this.broadcastsAnalytics > 0 ? this.getSummaryByKey('revenue', this.broadcastsAnalytics): 0;
      
      })
    }
    
  },
  watch:{
    dateFilter: {
      immediate:true,
      handler(newVal){
        if(newVal){
          const nextDay = new Date(newVal[1]);
          nextDay.setDate(newVal[1].getDate() + 1)

          let query = `project=${localStorage.getItem('current_project')}`
          query+= `&dateFrom=${this.formatDate(newVal[0])}&dateTo=${this.formatDate(nextDay)}`;  

          this.applyFilter(query)
        }
      }
    }
  }
};
</script>

<template>
  <div class="card">
    <i class="mdi mdi-help-circle-outline font-size-18 top-right-menu text-primary" v-b-popover.hover.left="$t('kpi.broadcasts_tooltips')"></i>
    <div class="card-body">
      <div class="row h-100">
        <h4 class="card-title mb-2">{{'Broadcasts'}}</h4>
        <div>
          <p class="mb-2">Revenue</p>
          <h4 v-b-popover.hover.bottom="`$${parseMoneyWithLocale('revenue')}`">{{ `$${parseMoneyReducedWithLocale(revenue)}` }}</h4>
        </div>
        <div class="w-60">
          <apexchart ref="revenueChart" class="apex-charts" :options="chartOptions"  width="100%" height="120px" dir="ltr" :series="series.length > 0 ? series : []" ></apexchart>
        </div>
        <hr/>
        <div class="table-responsive">
          <table class="table b-table">
            <tbody class="">
              <tr>
                <td class="text-start">{{$t('broadcast_analyze.sent')}}</td>
                <td class="text-end fw-bold" v-b-popover.hover.bottom="executions">{{parseCountReduced(executions)}}</td>
              </tr>
              <tr>
                <td class="text-start">{{$t('broadcast_analyze.delivery_rate')}}</td>
                <td class="text-end fw-bold">{{delivery_rate}}</td>
              </tr>
              <tr>
                <td class="text-start">{{$t('broadcast_analyze.open_rate')}}</td>
                <td class="text-end fw-bold">{{open_rate}}</td>
              </tr>
              <tr>
                <td class="text-start">{{$t('broadcast_analyze.click_rate')}}</td>
                <td class="text-end fw-bold">{{click_rate}}</td>
              </tr>
              <tr>
                <td class="text-start">{{$t('broadcast_analyze.conversion_rate')}}</td>
                <td class="text-end fw-bold">{{conversion_rate}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center">
          <a href="/broadcast-analyze" class="btn btn-primary btn-sm"> {{ $t('kpi.view_more') }}  <i class="mdi mdi-chevron-right ml-1"></i>
          </a>
        </div>
      </div> 
    </div>
  </div>
</template>

<style scoped>
 .top-right-menu {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
}

</style>
