<script>
import helpers from "../../../helpers";

import {
  analyticsMethods,
} from "@/state/helpers";

export default {
  data() {
    return {
      
      chartOptions: {
            chart: {
              width: "100%",
              height: 200,
              type: "area",
              sparkline: {
                enabled: true,
              },
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
              width: 1.5,
            },
            fill: {
              type: "gradient",
              gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [50, 100, 100, 100],
              },
            },
            colors: ["#5e40bf"],
            xaxis: {
              type: 'datetime',
              show: false,
            },            
      },
      series: [
        {
          name: "Revenue",
          data: [],
        },
      ],
      journeysAnalytics:[],
      revenue: '-',
      executions: '-',
      ended: '-',
      canceled: '-',
      notifications: '-',
      conversion_rate: '-',
      periodLength: 0
    };
  },
  components:{},
  props: {
    dateFilter : Array
  },
  mounted(){
    const self = this;
    
    this.chartOptions.yaxis = {
        show: false,
        labels: {
          formatter: function (valor) {
            return `$${self.parseMoneyWithLocale(valor)}`;
          }
        }
      }
      this.$refs.revenueChart.updateOptions(this.chartOptions);
  },
  computed: {},
  methods: {
    ...analyticsMethods,
    ...helpers,

    getSummaryValueByKey(key, data) {
      if(data.length == 0){
        return 0;
      }else{
        return data.filter(x => x.label === key)
        .reduce((sum, x) => sum + parseFloat(x.value, 10), 0);
      }
    },

    applyFilter(query){
      this.getJourneysReports(query).then((res)=>{
        this.journeysAnalytics = res.data;
        
        this.series[0].data = Array.from(this.journeysAnalytics.filter(x => x.label === "revenue").map(x => [new Date(x.date).getTime(), parseFloat(x.value, 10)]));
        this.series[0].data.sort((a, b) => a[0] - b[0]);
        this.$refs.revenueChart?.updateSeries(this.series,true);

        this.executions = res.data.length > 0 ? this.getSummaryValueByKey('executions', this.journeysAnalytics): 0; 
        this.ended = res.data.length > 0 ? this.getSummaryValueByKey('ended', this.journeysAnalytics): 0;
        this.canceled = res.data.length > 0 ? this.getSummaryValueByKey('cancel', this.journeysAnalytics): 0;
        this.notifications = res.data.length > 0 ? this.getSummaryValueByKey('notifications', this.journeysAnalytics): 0; 
        this.revenue = res.data.length > 0 ? this.getSummaryValueByKey('revenue', this.journeysAnalytics): 0; 
        
        const period = this.journeysAnalytics.filter(x => x.label === "conversion_rate");
        period.sort((a,b)=>new Date(a.date).getTime()- new Date(b.date).getTime());

        if(period.length > 1){
          const timeDifference = new Date(period[period.length-1].date).getTime() - new Date(period[0].date).getTime();
          this.periodLength =  timeDifference / (1000 * 3600 * 24) + 1;
        }else{
          this.periodLength = 0;
        } 
        this.conversion_rate = this.periodLength == 0 ? res.data.length > 0 ? this.parsePercentage(this.getSummaryValueByKey('conversion_rate', this.journeysAnalytics)): 0 : this.parsePercentage(this.getSummaryValueByKey('conversion_rate', this.journeysAnalytics)/ this.periodLength);
      })
    }
    
  },
  watch:{
    dateFilter: {
      immediate:true,
      handler(newVal){
        if(newVal){
          const nextDay = new Date(newVal[1]);
          nextDay.setDate(newVal[1].getDate() + 1)

          let query = `project=${localStorage.getItem('current_project')}`
          query+= `&dateFrom=${this.formatDate(newVal[0])}&dateTo=${this.formatDate(nextDay)}`;  

          this.applyFilter(query)
        }
      }
    }
  }
};
</script>

<template>
  <div class="card">
    <i class="mdi mdi-help-circle-outline font-size-18 top-right-menu text-primary" v-b-popover.hover.left="$t('kpi.jurneys_tooltips')"></i>
    <div class="card-body">
      <div class="row h-100">
        <h4 class="card-title mb-2">{{'Journeys'}}</h4>
        <div>
          <p class="mb-2">Revenue</p>
          <h4 v-b-popover.hover.bottom="`$${parseMoneyWithLocale('revenue')}`">{{ `$${parseMoneyReducedWithLocale(revenue)}` }}</h4>
        </div>
        <div class="w-60">
          <apexchart ref="revenueChart" class="apex-charts" :options="chartOptions"  width="100%" height="120px" dir="ltr" :series="series.length > 0 ? series : []" ></apexchart>
        </div>
        <hr/>
        <div class="table-responsive">
          <table class="table b-table">
            <tbody class="">
              <tr>
                <td class="text-start">{{$t('journeys_analyze.executions')}}</td>
                <td class="text-end fw-bold" v-b-popover.hover.bottom="executions">{{parseCountReduced(executions)}}</td>
              </tr>
              <tr>
                <td class="text-start">{{$t('journeys_analyze.ended')}}</td>
                <td class="text-end fw-bold" v-b-popover.hover.bottom="ended">{{parseCountReduced(ended)}}</td>
              </tr>
              <tr>
                <td class="text-start">{{$t('journeys_analyze.canceled')}}</td>
                <td class="text-end fw-bold" v-b-popover.hover.bottom="canceled">{{parseCountReduced(canceled)}}</td>
              </tr>
              <tr>
                <td class="text-start">{{$t('journeys_analyze.notifications')}}</td>
                <td class="text-end fw-bold" v-b-popover.hover.bottom="notifications">{{parseCountReduced(notifications)}}</td>
              </tr>
              <tr>
                <td class="text-start">{{$t('journeys_analyze.conversion_rate')}}</td>
                <td class="text-end fw-bold">{{`${conversion_rate}%`}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center">
          <a href="/journey-analyze" class="btn btn-primary btn-sm"> {{ $t('kpi.view_more') }}  <i class="mdi mdi-chevron-right ml-1"></i>
          </a>
        </div>
      </div> 
    </div>
  </div>
</template>

<style scoped>
 .top-right-menu {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
}

</style>
